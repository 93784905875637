import React from "react";
import { Flex, Spinner } from "@chakra-ui/react";

import WithAppLayout from "../../components/WithAppLayout";
import OrgWizard from "../../components/orgWizard/orgWizard";
import CreateOrgBanner from "../../components/orgWizard/CreateOrgBanner";
import WelcomeBanner from "../../components/WelcomeBanner";
import StripeBanner from "../../components/StripeBanner";

function DashboardPage({ loading, refetch, currentUser }) {
  const org = currentUser?.organization;

  const [createOrg, setCreateOrg] = React.useState(org && org.id);

  React.useEffect(() => {
    if (org && org.id) {
      setCreateOrg(true);
    }
  }, [org]);

  const renderInner = () => {
    if (loading) {
      return <Spinner size="xl" color="primary.500" />;
    }
    if (!org?.completedOnboardingAt) {
      if (createOrg) {
        return <OrgWizard organization={org} refetch={refetch} />;
      } else {
        return (
          <CreateOrgBanner
            createOrg={() => {
              setCreateOrg(true);
            }}
            organization={org}
          />
        );
      }
    }

    // render stripe stuff
    if (!org?.stripeAccountId) {
      return <StripeBanner currentUser={currentUser} />;
    }

    // render welcome message
    return <WelcomeBanner />;
  };

  return (
    <Flex direction={{ base: "column", xl: "row" }} pt={{ base: "90px" }}>
      <Flex direction="column" width="stretch" maxW={"1000px"}>
        {renderInner()}
      </Flex>
    </Flex>
  );
}

export default WithAppLayout(DashboardPage);
