import React, { useEffect, useState } from "react";
import _ from "lodash";

import "./stripe.css";
import { Box, Button, Flex, Link } from "@chakra-ui/react";
import DonationSuccessCard from "./DonationSuccessCard";
import colors from "../../helpers/colors";

export default function DonationSuccess({
  paymentIntent,
  publicOrgInfo,
  grandTotalCents,
  isSubscription,
}) {
  if (!paymentIntent) {
    return null;
  }

  return (
    <Flex
      w="100%"
      me="auto"
      h="100%"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      px="10px"
    >
      <DonationSuccessCard
        publicOrgInfo={publicOrgInfo}
        grandTotalCents={grandTotalCents}
        paymentIntent={paymentIntent}
        isSubscription={isSubscription}
      />

      <Link
        href={"impactlocal://postdonation"}
        mt="20px"
        colorScheme="green"
        borderRadius={30}
        cursor="default"
        backgroundColor={colors.green}
        color={colors.white}
        fontWeight="600"
        px={5}
        py={2}
      >
        Continue exploring
      </Link>
    </Flex>
  );
}
